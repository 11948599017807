<template>
  <div>
    <v-dialog
      v-model="$store.state.dialog"
      persistent
      :overlay="false"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card flat>
        <v-card-title>
          <span class="headline">
            {{ $store.state.dialogType === "add" ? "اضافة" : "تعديل" }}
            شركة
          </span>

          <v-spacer></v-spacer>
          <v-btn icon @click="$store.state.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <v-form v-model="valid">
            <v-row v-if="!$store.state.loading">
              <v-col cols="12">
                <h3>معلومات الشركة</h3>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  label="اسم الشركة"
                  hide-details="auto"
                  name="title"
                  :rules="[$rules.required()]"
                  v-model="inputs.data.name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  label="رابط الشركة"
                  hide-details="auto"
                  name="title"
                  :rules="[$rules.required()]"
                  v-model="inputs.data.url"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <h3>معلومات صاحب الشركة</h3>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  label="اسم المستخدم"
                  hide-details="auto"
                  name="title"
                  :rules="[$rules.required()]"
                  v-model="user.username"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  label="البريد الالكتروني"
                  hide-details="auto"
                  name="title"
                  :rules="[$rules.required(), $rules.emailFormat()]"
                  v-model="user.email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  outlined
                  label="كلمة المرور"
                  hide-details="auto"
                  name="title"
                  :rules="[$rules.required()]"
                  v-model="user.password"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <div class="d-flex justify-end">
                  <v-btn large @click="add" color="primary">
                    <v-icon left>mdi-content-save</v-icon>حفظ
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <div v-else class="d-flex justify-center mt-10 pt-10">
              <v-progress-circular indeterminate size="60"></v-progress-circular>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      inputs: {
        data: {
          name: "",
          url: "",
          user: ""
        }
      },
      user: {
        username: "",
        email: "",
        password: "",
        role: 1
      },
      loading: false,
      text: "",
      isEdit: false,
      valid: false
    };
  },
  methods: {
    async add() {
      this.$store.commit("setLoading", true);
      if (this.$store.state.dialogType == "edit") {
        if(this.inputs.data.user){
          await this.$http.put(
            "/users/" + this.inputs.data.user,
            this.user
          );
        } else {
          let res = await this.$http.post("/users", this.user);
          this.inputs.data.user = res.data.id;
        }

        await this.$http.put(
          "/companies/" + this.$store.state.item.id,
          this.inputs
        );
      } else {
        let res = await this.$http.post("/users", this.user);
        this.inputs.data.user = res.data.id;
        await this.$http.post("/companies", this.inputs);
      }
      this.$root.$emit("refresh");
      this.$store.commit("setLoading", false);
      this.$store.state.dialog = false;
    }
  },
  async mounted() {
    // listen to the event

    this.$root.$on("fill-fields", data => {
      Object.keys(data).forEach(key => {
        this.inputs.data[key] = data[key];
      });
      if(data.user){
        this.user.username = data.user.username;
        this.user.email = data.user.email;
        this.inputs.data.user = data.user.id;
      }
    });
  },
  watch: {
    "$store.state.dialog"(val) {
      if (val == false) {
        this.inputs.data = {
          name: "",
          url: "",
          user:""
        };
        this.user = {
          username: "",
          email: "",
          password: "",
          role: 1
        };
      }
    }
  }
};
</script>
