<template>
  <div>
    <v-card :loading="$store.state.loading" elevation="0" class="transparent">
      <v-card-title>
        <h3>الشركات</h3>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="openAddDialog()">اضافة شركة</v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :ripple="false"
              :items="companies"
              hide-default-footer
              disable-pagination
              :loading="$store.state.loading"
              no-data-text="لا يوجد"
              loading-text="جار التحميل..."
            >
              <template v-slot:item.logo="{ item }">
                <v-img
                  v-if="item.logo"
                  :src="$service.image  + item.logo.url"
                  :alt="item.title"
                  max-width="100"
                  max-height="100"
                ></v-img>
              </template>
              <template v-slot:item.createdAt="{ item }">
                {{ $service.formatDate(item.createdAt) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="d-flex align-center">
                  <v-btn color="primary" @click="openEditDialog(item)" >
                    <v-icon>mdi-pencil</v-icon>
                    تعديل المعلومات الاساسية
                  </v-btn>
                  <v-btn class="mx-2" color="secondary" :to="`/companies/${item.id}`" outlined>
                    تعديل البيانات
                  </v-btn>
                  
                  <v-btn
                    color="error"
                    text
                    @click="deleteItem(item, item.title)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <Add />
  </div>
</template>
<script>
import Add from "./Add.vue";
export default {
  components: { Add },
  data() {
    return {
      companies: [],
      total: 1,

      headers: [
        { text: "الاسم", value: "name" },
        { text: "الشعار", value: "logo" },
        { text: "الرابط", value: "url" },
        { text: "تاريخ الاضافة", value: "createdAt" },
        { text: "الاجراءات", value: "actions" },
      ],
      options: {
        populate: "*",
        filters: {
        },
        pagination: {
          page: 1,
          pageSize: 15,
        },
      },
    };
  },
  created() {
    this.getnews();
    this.$root.$on("refresh", () => {
      this.getnews();
    });
  },
  methods: {
     getnews() {
      this.$store.commit("setLoading", true);
      this.$http
        .get("/companies", {
          params: this.options,
          paramsSerializer: this.$service.qsParams,
        })
        .then((response) => {
          this.companies = response.data.data;
          // this.total = 1;
          this.$store.commit("setLoading", false);
        });
    },
    openAddDialog() {
      this.$store.state.dialog = true;
      this.$store.state.dialogType = "add";
    },
    openEditDialog(item) {
      this.$store.state.item = item;

      this.$root.$emit("fill-fields", item);
      this.$store.state.dialog = true;
      this.$store.state.dialogType = "edit";
    },
    deleteItem(item, title) {
      title;
      
      this.$store.commit("setLoading", true);
      if(item.user){
        this.$http.delete("/users/" + item.user.id);

      }
      this.$http.put("/companies/" + item.id, {
        data: {
          publishedAt: null,
        },
      });
      this.companies = this.companies.filter((x) => x.id != item.id);
      // this.getnews()
      this.$store.commit("setLoading", false);
    },
  },
  watch: {
    options: {
      handler() {
        this.getnews();
      },
      deep: true,
    },
  },
};
</script>
